<template>
  <v-container persistent fluid>
    <v-toolbar tile style="font-family: Georgia">
      
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        hide-details
        dense
        clearable
      ></v-text-field>
    </v-toolbar>
    <v-data-table
      style="font-size: 20px; font-family: Georgia"
      :headers="headers"
      class="elevation-1"
      dense
      hide-default-footer
      :items-per-page="itemsPerPage"
      :page.sync="page"
      :items="tableData"
      :search="search"
    >
      <template v-slot:[`item.send_request`]="{ item }">
        <span style="color: red; font-size: 14px"><b>{{ item.sendToSubcon_date }}</b></span
        ><br />
        <span style="color: green;font-size: 12px"
          >Date Sent to Subcon <br /><br />{{ item.kansenProposal_status }}</span
        >
      </template>

      <!-- KANSEN CHECKLIST COLUMN -->

      <template v-slot:[`item.UwkansenChecklist`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="pink" @click="kanseChecklist(item)"
                >mdi-playlist-check</v-icon
              >
            </v-btn> </template
          ><span>View Checklist</span>
        </v-tooltip>
      </template>


      <!-- ACTION COLUMN -->

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="primary" @click="openFinishedForm(item)">mdi-eye-outline</v-icon>
            </v-btn> </template
          ><span>View Finished Data</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-footer>
        <v-spacer/>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
            v-on="on"
            v-if="account.role == 'Admin'"
            @click="openRetrievingDialog()" 
            color="warning" 
            rounded
            fab
            style="margin-top:1%"
            >
            <v-icon size="30">mdi-file-restore-outline</v-icon>
            </v-btn>
          </template><span>Retrieve Data</span>
        </v-tooltip>
        
        <v-btn 
        v-if="account.role == 'Standard' || account.role == null" 
        fab class="success" 
        style="margin-top:1%; margin-left:1%" 
        @click="returnToMainPage()" ><v-icon size="30">mdi-home</v-icon>
        </v-btn>
    </v-footer>



    <div class="text-center pt-1" style="font-size: 12px">
      <v-pagination
        color="blue grey darken-4"
        v-model="page"
        :length="pageCount"
        :total-visible="5"
        right
      ></v-pagination>
    </div>
    <div>
      <editDialogVue Type="View"></editDialogVue>
    </div>

    <v-dialog v-model="retrievingDialog" width="300px">
      <v-card shaped dark>        
        <v-card  style="text-align:center">
          <span style="font-size:25px">Enter Code Number</span> 

            <v-card-text>
              <v-text-field 
              label="Code Number" 
              @keydown.enter="retrieveData()" 
              v-model="codeNumber" 
              v-mask="codeMask" 
              small 
              outlined 
              dense 
              hide-details
              placeholder="0000000-0000"
              clearable></v-text-field>
              <span v-if="!isCodeValid"  style="font-size:13px">Enter the code number you want to retrieve.</span>
              <span v-if="isCodeValid" style="color:rgb(90, 248, 90)"><b> Press the enter key to proceed.</b></span>
            </v-card-text>        
        </v-card>
      </v-card>
    </v-dialog>
    <div class="lds-spinner" v-if="this.loadingProposalFrom">
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
    </div>
  </v-container>
</template>

<script>
// import _ from 'lodash';
import axios from "axios";
import Swal from "sweetalert2";
import { bus } from "../main";
import editDialogVue from "../components/editDialog.vue";
export default {
  components: {
    editDialogVue,
  },
  data() {
    return {
      loadingProposalFrom:false,
      editDataFrom54:[],
      tableData:[],
      codeNumber:'',
      codeMask:'XXXXXXX-####',
      retrievingDialog:false,
      headers: [
        {
          text: "No.",
          value: "id",
          align: "center",
          width: "100px",
          class: "white--text",
        },
        {
          text: "Action",
          value: "actions",
          align: "center",
          width: "100px",
          class: "white--text",
        },
        {
          text: "Code Number",
          value: "code_number",
          align: "center",
          width: "200px",
          class: "white--text",
        },
        {
          text: "UW Checkbox Finished Date",
          value: "Checkbox_FP",
          align: "center",
          width: "245px",
          class: "white--text",
        },
        {
          text: "Hacchu Finished Date",
          value: "hacchu318",
          align: "center",
          width: "200px",
          class: "white--text",
        },
        {
          text: "Due Date in PCMS",
          value: "Kansen_DD",
          align: "center",
          width: "200px",
          class: "white--text",
        },
        {
          text: "Joutou Date in PCMS",
          value: "joutou",
          align: "center",
          width: "200px",
          class: "white--text",
        },
        {
          text: "Days Left from Joutou",
          value: "daysLeft",
          align: "center",
          width: "200px",
          class: "white--text",
        },
        {
          text: "New Subcon",
          value: "subcon318",
          align: "center",
          width: "200px",
          class: "white--text",
        },
        {
          text: "Old Subcon",
          value: "subcon318_old",
          align: "center",
          width: "200px",
          class: "white--text",
        },
        {
          text: "Specification",
          value: "specification",
          align: "center",
          width: "200px",
          class: "white--text",
        },
        {
          text: "Finished in PCMS Kansen Proposal",
          value: "Kansen_FP",
          align: "center",
          width: "250px",
          class: "white--text",
        },
        {
          text: "Kansen Checklist",
          value: "UwkansenChecklist",
          align: "center",
          width: "200px",
          class: "white--text",
        },
        {
          text: "First Request Start Date",
          value: "start_first_request",
          align: "center",
          width: "210px",
          class: "white--text",
        },
        {
          text: "First Request Finished Date",
          value: "finish_first_request",
          align: "center",
          width: "230px",
          class: "white--text",
        },
        {
          text: "Finished Plan",
          value: "send_request",
          align: "center",
          width: "200px",
          class: "white--text",
        },
      ],
      search: "",
      page: 1,
      itemsPerPage: 10,
      num: 1,
      ip: "",
      editDialog: false,
    
    };
  },
  methods: {
    retrieveData(){
      // const filteredData = this.allFinishedData.filter(el => el.code_number == this.codeNumber)
      // console.log(filteredData)
        const existInFinishPlan = this.allFinishedData.some(el => el.code_number == this.codeNumber)
        if(this.isCodeValid){
              if(existInFinishPlan){
                        Swal.fire({
                              icon: 'question',
                              html: 'You want to restore ' + this.codeNumber +' ?',
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              showCancelButton: true,
                              confirmButtonText: 'Confirm',
                              cancelButtonText: 'Cancel',
                              allowOutsideClick: () => false
                            }).then((result) => {
                              if (result.isConfirmed) {
                                axios.post(`${this.$BaseUrl}/api/restoreData`,{code_number : this.codeNumber}).then(()=>{
                                    Swal.fire({
                                        toast: true,
                                        position: "center-start",
                                        icon: 'success',
                                        title: "Data Retrieved" ,
                                        showConfirmButton: false,
                                        timer: 3000,
                                        color:'green',
                                                });
                                    this.$router.push("/Kansensystem")
                                  })
                              }                
                            });
                        
              }else{
                Swal.fire({
                              toast: true,
                              position: "bottom",
                              icon: 'error',
                              title: "No Data Found !",
                              showConfirmButton: false,
                              timer: 3000,
                              color:'green',
                              });
              }
        }
            
        
    },
    openRetrievingDialog(){
      this.retrievingDialog = true
    },
    async openFinishedForm(item) {
      
      const headers = { 'x-api-key' : this.awsHeaders };
            let dataFromHacchu = await axios.get(`${this.$BaseUrl}/api/hacchuData/${item.code_number}`,{headers})
            // console.log(dataFromHacchu.data,'testNEw');
            console.log(item);
            //   this.loadingProposalFrom = true
           

            setTimeout(() => {
                let MeterInfoObject = [{
                    ic_c1r7: dataFromHacchu.data[0].MeterInfo_ic_c1r7,
                    ic_c2: dataFromHacchu.data[0].MeterInfo_ic_c2,
                    ic_c2r2: dataFromHacchu.data[0].MeterInfo_ic_c2r2,
                    ic_c2r3: dataFromHacchu.data[0].MeterInfo_ic_c2r3,
                    ic_c2r4: dataFromHacchu.data[0].MeterInfo_ic_c2r4,
                    ic_c2r5: dataFromHacchu.data[0].MeterInfo_ic_c2r5,
                    ic_c2r6: dataFromHacchu.data[0].MeterInfo_ic_c2r6,
                    newCv55: dataFromHacchu.data[0].MeterInfo_newCv55,
                    sizeCV_c4r4: dataFromHacchu.data[0].MeterInfo_sizeCV_c4r4,
                    size_c4r2: dataFromHacchu.data[0].MeterInfo_size_c4r2,
                    size_c4r3: dataFromHacchu.data[0].MeterInfo_size_c4r3,
                    size_c4r5: dataFromHacchu.data[0].MeterInfo_size_c4r5,
                    size_c4r6: dataFromHacchu.data[0].MeterInfo_size_c4r6,
                    size_c4r7: dataFromHacchu.data[0].MeterInfo_size_c4r7,
                    size_cvt1: dataFromHacchu.data[0].MeterInfo_size_cvt1,
                }];

                let nonSocketObject = Object.assign(item,{
                            Value_c1 : dataFromHacchu.data[0].Value_c1,
                            wa_c1 : dataFromHacchu.data[0].wa_c1,
                            lenght_Ksc1 : dataFromHacchu.data[0].lenght_Ksc1,
                            lpi_6r6 : dataFromHacchu.data[0].lpi_6r6,
                            lpi_6r7 : dataFromHacchu.data[0].lpi_6r7,
                            fl_c7r6 : dataFromHacchu.data[0].fl_c7r6,
                            fl_c7r7 : dataFromHacchu.data[0].fl_c7r7,
                            cc_c1 : dataFromHacchu.data[0].cc_c1,
                            sizeCV_c1 : dataFromHacchu.data[0].sizeCV_c1,
                            type : dataFromHacchu.data[0].type,
                            cc_c2 : dataFromHacchu.data[0].cc_c2,
                            total_cv55_3:dataFromHacchu.data[0].total_cv55_3,
                            lpi_cv55_4:dataFromHacchu.data[0].lpi_cv55_4,
                            fl_cv55_5:dataFromHacchu.data[0].fl_cv55_5,
                            MeterInfo : MeterInfoObject[0],
                            wattsDetails : dataFromHacchu.data[0].wattsDetails,
                            sizeCV_c2 : dataFromHacchu.data[0].sizeCV_c2,
                            quantity_c2: dataFromHacchu.data[0].quantity_c2 == null ? 1 : dataFromHacchu.data[0].quantity_c2,
                            finish_first_request : item.finish_first_request,
                           

                    })

                    // console.log(nonSocketObject,'LINE373');
                        bus.$emit('editDialog',nonSocketObject)
                        // console.log(nonSocketObject);
                        this.loadingProposalFrom = false;
                    }, 1000);

    
     
    },

    //NOTE kansen checklist

    kanseChecklist(item) {
      window.open(
        `http://hrdapps54/kansenchecklist/index.php?code_number=${item.code_number}`,
        "_blank"
      );
    },

    // gathering data 3months
    loadFinishedPlan() {

      axios({
            method: 'get',
            url: `${this.$BaseUrl}/api/getFinishedplanData`,
            headers: {
            'x-api-key': this.awsHeaders
            }
        }).then(res => {
            this.tableData = res.data
        }).catch(error => {
        console.error("Error fetching data:", error);
        });
          
    },


    returnToMainPage(){
      this.$router.push("/Kansensystem")
    },

    
  }, //END OF METHODS
  mounted() {
 
    this.loadFinishedPlan();
   
  },

  computed: {
    isCodeValid() {
      return this.codeNumber ?(this.codeNumber.length === this.codeMask.length) :'' ;
    },

    pageCount() {
      let a = Math.ceil(this.itemsPerPage);
      return a;
    },
  },
};
</script>
<style>
.classT tr:hover {
  background-color: #eeeeee;
}

.classT {
  border-left: 2px solid gray;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  background-color: #1f2833;
  font-size: 15px !important;
  color: white;
}

.classT,
th,
td,
tr {
  border: 1px solid rgb(221, 140, 0);
}

.thStyle {
  background-color: rgb(26, 32, 38);
}

.lds-spinner {
  color: official;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(246, 119, 0);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
